export enum ISO6391LanguageCode {
    en = 'en',
    ar = 'ar',
    bg = 'bg',
    hr = 'hr',
    cs = 'cs',
    fr = 'fr',
    de = 'de',
    he = 'he',
    hu = 'hu',
    no = 'no',
    pl = 'pl',
    pt = 'pt',
    ro = 'ro',
    ru = 'ru',
    sk = 'sk',
    es = 'es',
    tr = 'tr',
    uk = 'uk',
}

export type ISO6391LanguageCodeOption =
    | 'en'
    | 'bg'
    | 'hr'
    | 'ar'
    | 'fr'
    | 'de'
    | 'cs'
    | 'he'
    | 'hu'
    | 'no'
    | 'pl'
    | 'pt'
    | 'ro'
    | 'ru'
    | 'sk'
    | 'es'
    | 'tr'
    | 'uk';

export type HashMap<T> = {
    [key: string]: T;
};
export type TranslationsObject = HashMap<string>;
