import { useEffect } from 'react';
import { getAuthHeader, getHeaders } from '../api/apiClient';
import { EncryptedObjectIdString } from '../types/common';
import { getApiUrl } from '../utils/apiUrl';

interface UsePostCreationNotificationBeforeUnloadProps {
    encryptedSimpleFormId: EncryptedObjectIdString;
    issueId: string;
    enabled?: boolean;
}

export const usePostCreationNotificationBeforePageLeave = ({
    encryptedSimpleFormId,
    issueId,
    enabled = true,
}: UsePostCreationNotificationBeforeUnloadProps) => {
    useEffect(() => {
        const handleBeforeUnload = () => {
            const token = localStorage.getItem('token');
            const url = `${getApiUrl()}/v2/simpleForm/${encryptedSimpleFormId}/issue/${issueId}/creationNotification`;
            fetch(url, {
                method: 'GET',
                headers: {
                    ...getHeaders(),
                    ...(token ? getAuthHeader(token) : {}),
                },
                keepalive: true,
            });
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [encryptedSimpleFormId, issueId, enabled]);
};
