import { ObjectIdString } from '../types/common';
import { ApiClient, createResourceService } from './resourceService';

interface SignedUrlResponse {
    signedRequest: string;
    thumbnailSignedRequest: string;
    imageSignedRequest: string;
}

interface GetMapUrlParams {
    encryptedSimpleFormId: string;
    levelId: ObjectIdString;
    documentationId: ObjectIdString;
    versionId: ObjectIdString;
}

export const getLocationMapSignedUrl = createResourceService<
    GetMapUrlParams,
    string
>({
    getPath: ({ encryptedSimpleFormId, documentationId, versionId }) =>
        `/simpleForm/${encryptedSimpleFormId}/documentation/${documentationId}/version/${versionId}/download`,
    getParams: ({ levelId }) => ({ level: levelId }),
    mapResponse: (response: SignedUrlResponse) =>
        response.imageSignedRequest ||
        response.thumbnailSignedRequest ||
        response.signedRequest,
    client: ApiClient.Unauthorized,
});
