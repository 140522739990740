import ar from './translations/ar_AE.json';
import bg from './translations/bg.json';
import cs from './translations/cs.json';
import de from './translations/de.json';
import en from './translations/en.json';
import es from './translations/es.json';
import fr from './translations/fr.json';
import he from './translations/he.json';
import hr from './translations/hr.json';
import hu from './translations/hu.json';
import no from './translations/no.json';
import pl from './translations/pl.json';
import pt from './translations/pt.json';
import ro from './translations/ro.json';
import ru from './translations/ru_UA.json';
import sk from './translations/sk.json';
import tr from './translations/tr.json';
import uk from './translations/uk.json';
import { ISO6391LanguageCodeOption, TranslationsObject } from './types';

export function getUnescapedTranslationObjectByLocale(
    locale: ISO6391LanguageCodeOption,
): TranslationsObject {
    switch (locale) {
        case 'en':
            return en;
        case 'ar':
            return ar;
        case 'bg':
            return bg;
        case 'hr':
            return hr;
        case 'cs':
            return cs;
        case 'fr':
            return fr;
        case 'de':
            return de;
        case 'he':
            return he;
        case 'hu':
            return hu;
        case 'no':
            return no;
        case 'pl':
            return pl;
        case 'pt':
            return pt;
        case 'ro':
            return ro;
        case 'ru':
            return ru;
        case 'sk':
            return sk;
        case 'es':
            return es;
        case 'tr':
            return tr;
        case 'uk':
            return uk;
        default:
            return en;
    }
}
