import { useAuth0 } from '@auth0/auth0-react';
import { postCreationNotification } from '../services/notificationService';
import { EncryptedObjectIdString } from '../types/common';

export const useCreationNotification = () => {
    const { getAccessTokenSilently } = useAuth0();
    const sendCreationNotification = async (
        encryptedSimpleFormId: EncryptedObjectIdString,
        issueId: string,
    ): Promise<string> => {
        if (!encryptedSimpleFormId || !issueId) {
            throw new Error(' not enough params to send creation notification');
        }
        console.log('postCreationNotification');
        return await postCreationNotification(await getAccessTokenSilently(), {
            encryptedSimpleFormId,
            issueId,
        });
    };

    return { sendCreationNotification };
};
