import { ApiClient, createResourceService } from './resourceService';

interface PostCreationNotificationParams {
    encryptedSimpleFormId: string;
    issueId: string;
}

export const postCreationNotification = createResourceService<
    PostCreationNotificationParams,
    string
>({
    method: 'get',
    getPath: ({ encryptedSimpleFormId, issueId }) =>
        `/simpleForm/${encryptedSimpleFormId}/issue/${issueId}/creationNotification`,
    client: ApiClient.Authorized,
});
