import React, { RefObject, useCallback } from 'react';

type ClickHandler = (event: React.MouseEvent | React.TouchEvent) => void;

interface HandlePinPositionProps {
    imageRef: RefObject<HTMLImageElement>;
    containerRef: RefObject<HTMLDivElement>;
    setPinPosition: (x: number, y: number) => void;
    isInImageBounds: (x: number, y: number) => boolean;
}

export const useHandlePinPosition = ({
    imageRef,
    containerRef,
    setPinPosition,
    isInImageBounds,
}: HandlePinPositionProps): ClickHandler => {
    return useCallback(
        (event: React.MouseEvent | React.TouchEvent) => {
            const imageBounds = imageRef.current?.getBoundingClientRect();
            const wrapperBounds = containerRef.current?.getBoundingClientRect();

            if (imageBounds && wrapperBounds) {
                let clientX: number;
                let clientY: number;

                if ('touches' in event && event.touches.length > 0) {
                    // TouchEvent
                    clientX = event.touches[0].clientX;
                    clientY = event.touches[0].clientY;
                } else if ('clientX' in event) {
                    // MouseEvent
                    clientX = event.clientX;
                    clientY = event.clientY;
                } else {
                    return;
                }

                const clickX = clientX - imageBounds.x;
                const clickY = clientY - imageBounds.y;

                const normalizedPositionX = clickX / imageBounds.width;
                const normalizedPositionY = clickY / imageBounds.height;

                if (isInImageBounds(normalizedPositionX, normalizedPositionY)) {
                    setPinPosition(normalizedPositionX, normalizedPositionY);
                }
            }
        },
        [imageRef, containerRef, setPinPosition, isInImageBounds],
    );
};
