import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { GaAction, GaCategory } from '../config/googleAnalytics';
import { useError } from '../hooks/useError';
import { useFetchResource } from '../hooks/useFetchResource';
import { useIssue } from '../hooks/useIssue';
import { useLevels } from '../hooks/useLevels';
import { usePreventPageLeave } from '../hooks/usePreventPageLeave';
import { useProjects } from '../hooks/useProjects';
import { useSites } from '../hooks/useSites';
import { UrlParam, useRequiredEncryptedObjectIdParam } from '../types/common';
import { SelectedLocationType } from '../types/dto/issue';
import { LevelDto, SublocationMapDto } from '../types/dto/level';
import { ProjectDto } from '../types/dto/project';
import { SiteDto } from '../types/dto/site';
import { logAnalyticsEvent } from '../utils/logger/logAnalyticsEvent';
import {
    isEncryptedObjectIdString,
    setSimpleFormId,
} from '../utils/simpleFormId';
import { MapTransformWrapper } from './map/mapTransformWrapper';
import { SingleSelect } from './singleSelect';
import { FormSkeleton } from './skeleton';
interface ResourceEmptyStateProps {
    resourceType: 'projects' | 'sites' | 'levels';
}
interface FormState {
    title: string;
    description: string;
    project: ProjectDto | null;
    site: SiteDto | null;
    level: LevelDto | null;
    selectedMap: SublocationMapDto | null;
    pinPositionX: number;
    pinPositionY: number;
}
const ResourceEmptyState: React.FC<ResourceEmptyStateProps> = ({
    resourceType,
}) => {
    const intl = useIntl();

    return (
        <Typography sx={{ mt: 3, textAlign: 'center' }}>
            {intl.formatMessage({
                id: `wrong_config_no_${resourceType}`,
            })}
        </Typography>
    );
};

export const IssueForm: React.FC = () => {
    const { fetchProjects } = useProjects();
    const { fetchSites } = useSites();
    const { fetchLevels } = useLevels();
    const { createIssue } = useIssue();
    const [projects, setProjects] = useState<ProjectDto[] | null>(null);
    const [sites, setSites] = useState<SiteDto[] | null>(null);
    const [levels, setLevels] = useState<LevelDto[] | null>(null);
    const encryptedSimpleFormId = useRequiredEncryptedObjectIdParam(
        UrlParam.encryptedSimpleFormId,
    );
    if (isEncryptedObjectIdString(encryptedSimpleFormId)) {
        setSimpleFormId(encryptedSimpleFormId);
    }
    const [formState, setFormState] = useState<FormState>({
        title: '',
        description: '',
        project: null,
        site: null,
        level: null,
        selectedMap: null,
        pinPositionX: 0.5,
        pinPositionY: 0.5,
    });
    const [isFormDirty, setIsFormDirty] = useState(false);
    usePreventPageLeave({
        isEnabled: isFormDirty,
    });
    const intl = useIntl();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const { error, handleError } = useError();

    useFetchResource({
        fetchFn: fetchProjects,
        dependencies: [encryptedSimpleFormId],
        params: { encryptedSimpleFormId },
        resourceName: 'projects',
        setData: (data) => {
            setIsLoading(false);
            setProjects(data);
        },
        onError: handleError,
        setIsLoading: setIsLoading,
    });

    useFetchResource({
        fetchFn: fetchSites,
        dependencies: [formState.project?._id],
        params: formState.project?._id
            ? { projectId: formState.project?._id, encryptedSimpleFormId }
            : undefined,
        resourceName: 'sites',
        onError: handleError,
        setData: setSites,
    });

    useFetchResource({
        fetchFn: fetchLevels,
        dependencies: [formState.project?._id, formState.site?._id],
        params:
            formState.project?._id && formState.site?._id
                ? {
                      projectId: formState.project?._id,
                      siteId: formState.site?._id,
                      encryptedSimpleFormId,
                  }
                : undefined,
        resourceName: 'levels',
        onError: handleError,
        setData: setLevels,
    });

    const handleTitleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFormState((prev) => ({
                ...prev,
                title: event.target.value,
            }));
            setIsFormDirty(true);
        },
        [],
    );

    const handleDescriptionInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFormState((prev) => ({
                ...prev,
                description: event.target.value,
            }));
            setIsFormDirty(true);
        },
        [],
    );

    const handleSend = useCallback(async () => {
        if (
            !formState.title ||
            !formState.project ||
            !formState.level ||
            !formState.site
        ) {
            throw new Error('Not enough data');
        }
        logAnalyticsEvent({
            category: GaCategory.Button,
            action: GaAction.Click,
            label: 'issue-create',
            eventName: 'issue-create',
        });
        const issueId = await createIssue({
            title: formState.title,
            description: formState.description,
            project: formState.project._id,
            site: formState.site._id,
            level: formState.level._id,
            selectedLocationType: SelectedLocationType.pin,
            positionOnMap: {
                x: formState.pinPositionX,
                y: formState.pinPositionY,
            },
        });
        setIsFormDirty(false);

        const newPath = `/project/${formState.project._id}/issue/${issueId}`;
        navigate(location.pathname + newPath, { replace: false });
    }, [formState, createIssue, navigate]);

    const setLevel = useCallback((value: LevelDto | null) => {
        setFormState((prev) => ({
            ...prev,
            level: value,
            selectedMap:
                value && Array.isArray(value.maps) ? value.maps[0] : null,
        }));
    }, []);

    const setSite = useCallback((value: SiteDto | null) => {
        setFormState((prev) => ({
            ...prev,
            site: value,
            level: null,
            selectedMap: null,
        }));
    }, []);

    const setProject = useCallback((value: ProjectDto | null) => {
        setFormState((prev) => ({
            ...prev,
            project: value,
            site: null,
            level: null,
            selectedMap: null,
        }));
    }, []);

    const setPinPosition = useCallback((x: number, y: number) => {
        setFormState((prev) => ({
            ...prev,
            pinPositionX: x,
            pinPositionY: y,
        }));
    }, []);
    if (error) {
        throw error;
    }
    if (isLoading || !encryptedSimpleFormId) {
        return <FormSkeleton />;
    }

    if (projects && projects.length === 0) {
        console.log('wrong config');
        return (
            <Typography sx={{ mt: 3, textAlign: 'center' }}>
                {intl.formatMessage({
                    id: 'wrong_config_no_projects',
                })}
            </Typography>
        );
    }

    if (sites && sites.length === 0) {
        return (
            <Typography sx={{ mt: 3, textAlign: 'center' }}>
                {intl.formatMessage({
                    id: 'wrong_config_no_sites',
                })}
            </Typography>
        );
    }
    const resourceChecks = [
        { type: 'projects', data: projects, dependency: true },
        { type: 'sites', data: sites, dependency: Boolean(formState.project) },
        {
            type: 'levels',
            data: levels,
            dependency: Boolean(formState.project && formState.site),
        },
    ] as const;

    for (const { type, data, dependency } of resourceChecks) {
        if (dependency && data && data.length === 0) {
            return <ResourceEmptyState resourceType={type} />;
        }
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mx: { xs: 1, sm: 2, md: 4 },
                mt: 1,
                zIndex: 2,
            }}
        >
            <Grid container rowSpacing={2} columnSpacing={8} sx={{ mt: 4 }}>
                <Grid item xs={12}>
                    <TextField
                        color="secondary"
                        label={intl.formatMessage({ id: 'issue_form_title' })}
                        data-qa="issue-form-summary"
                        fullWidth={true}
                        value={formState.title}
                        onChange={handleTitleInputChange}
                        variant="outlined"
                        multiline={true}
                        slotProps={{
                            htmlInput: { minLength: 3, maxLength: 150 },
                        }}
                        helperText={`${formState.title.length}/150`}
                        required={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        color="secondary"
                        label={intl.formatMessage({
                            id: 'issue_form_description',
                        })}
                        data-qa="issue-form-description"
                        fullWidth={true}
                        onChange={handleDescriptionInputChange}
                        value={formState.description}
                        variant="outlined"
                        multiline={true}
                        slotProps={{
                            htmlInput: { minLength: 0, maxLength: 3000 },
                        }}
                        helperText={`${formState.description.length}/3000`}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SingleSelect
                        color="secondary"
                        options={projects || []}
                        label={intl.formatMessage({
                            id: 'issue_form_project',
                        })}
                        helperText=""
                        dataQa="issue-form-project"
                        disabled={false}
                        setValue={setProject}
                        currentValueId={formState.project?._id}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SingleSelect
                        color="secondary"
                        options={sites || []}
                        label={intl.formatMessage({
                            id: 'issue_form_location',
                        })}
                        disabled={!formState.project}
                        dataQa="issue-form-location"
                        helperText={
                            !formState.project
                                ? intl.formatMessage({
                                      id: 'form_select_project_before_location',
                                  })
                                : ''
                        }
                        setValue={setSite}
                        currentValueId={formState.site?._id}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SingleSelect
                        color="secondary"
                        options={levels || []}
                        label={intl.formatMessage({
                            id: 'issue_form_sublocation',
                        })}
                        data-qa="issue-form-sublocation"
                        disabled={!formState.site}
                        helperText={
                            !formState.site
                                ? intl.formatMessage({
                                      id: 'form_select_location_before_sublocation',
                                  })
                                : ''
                        }
                        setValue={setLevel}
                        currentValueId={formState.level?._id}
                    />
                </Grid>
            </Grid>
            {!formState.level && (
                <Typography
                    sx={{ margin: 'auto', mb: 1, mt: { xs: 2, sm: 1, md: 0 } }}
                    variant="subtitle1"
                >
                    {intl.formatMessage({
                        id: 'issue_form_map_not_selected',
                    })}
                </Typography>
            )}
            {formState.level && !formState.selectedMap && (
                <Typography
                    sx={{ margin: 'auto', marginBottom: 1 }}
                    variant="subtitle2"
                >
                    {intl.formatMessage({
                        id: 'issue_form_no_map_available',
                    })}
                </Typography>
            )}
            {formState.level && formState.selectedMap && (
                <MapTransformWrapper
                    pinPositionX={formState.pinPositionX}
                    pinPositionY={formState.pinPositionY}
                    setPinPosition={setPinPosition}
                    levelId={formState.level._id}
                    documentationId={formState.selectedMap.documentationId}
                    versionId={formState.selectedMap?.versionId}
                />
            )}
            <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                    mx: 2,
                    margin: 'auto',
                    minWidth: { xs: '300px', sm: '400px' },
                }}
                data-qa="issue-form-submit"
                disabled={
                    !formState.title ||
                    !formState.project ||
                    !formState.site ||
                    !formState.level
                }
                onClick={handleSend}
            >
                {intl.formatMessage({ id: 'issue_form_create' })}
            </Button>
        </Box>
    );
};
