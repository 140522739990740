import axios from 'axios';
import { getApiUrl } from '../utils/apiUrl';
const hustroClientVersion = '2.91.0';

export const getHeaders = () => {
    return {
        'Content-Type': 'application/json',
        'hustro-client': 'simpleForm',
        'hustro-client-version': hustroClientVersion,
    };
};
export function getAuthHeader(token: string) {
    return { Authorization: `Bearer ${token}` };
}
export const authorizedApiClient = axios.create({
    baseURL: `${getApiUrl()}/v2`,
    headers: getHeaders(),
});

export const unauthorizedApiClient = axios.create({
    baseURL: `${getApiUrl()}/unauthorized/`,
    headers: getHeaders(),
});

export const publicApiClient = axios.create({
    baseURL: `${getApiUrl()}/public`,
    headers: getHeaders(),
});
