import { useAuth0 } from '@auth0/auth0-react';
import { Box, SxProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useAuthErrorHandler } from '../../hooks/useAuthErrorHandler';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useError } from '../../hooks/useError';
import { useFetchResource } from '../../hooks/useFetchResource';
import { useOrganization } from '../../hooks/useOrganization';
import { useSimpleForm } from '../../hooks/useSimpleForm';
import { SimpleFormDto } from '../../types/dto/simpleForm';
import { OrganizationModel } from '../../types/model/organization';
import { isEncryptedObjectIdString } from '../../utils/simpleFormId';
import { LoginForm } from '../../views/loginForm';
import { AuthSkeleton } from '../skeleton';

export interface LoginButtonProps {
    sx?: SxProps;
    onClick?: () => void;
}
export const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
    const { isAuthenticated, isLoading } = useAuth0();
    const { getToken, isUserAuthenticatedWithHustroApp } = useAuthToken();
    const { fetchOrganization } = useOrganization();
    const { fetchSimpleForm } = useSimpleForm();
    const [organization, setOrganization] = useState<OrganizationModel | null>(
        null,
    );
    const [simpleForm, setSimpleForm] = useState<SimpleFormDto | null>(null);
    const isMounted = useRef(true);
    useAuthErrorHandler();
    useEffect(() => {
        if (isMounted.current) {
            getToken();
        }
        return () => {
            isMounted.current = false;
        };
    }, [getToken]);
    const { error, handleError } = useError();
    const encryptedSimpleFormId = window.location.pathname.split('/form/')[1];

    useFetchResource({
        fetchFn: fetchSimpleForm,
        dependencies: [encryptedSimpleFormId],
        params:
            encryptedSimpleFormId &&
            isEncryptedObjectIdString(encryptedSimpleFormId)
                ? { encryptedSimpleFormId }
                : undefined,
        resourceName: 'simpleForm',
        setData: setSimpleForm,
        onError: handleError,
    });

    useFetchResource({
        fetchFn: fetchOrganization,
        dependencies: [simpleForm?.encryptedClientId],
        params: simpleForm?.encryptedClientId
            ? { encryptedClientId: simpleForm.encryptedClientId }
            : undefined,
        resourceName: 'organization',
        setData: setOrganization,
        onError: handleError,
    });

    if (error) {
        throw error;
    }
    if (isLoading) {
        return <AuthSkeleton sx={{ mt: 12, mx: 'auto' }} />;
    }

    if (!isAuthenticated) {
        return (
            <>
                <LoginForm
                    isUserAuthenticatedWithHustroApp={
                        isUserAuthenticatedWithHustroApp
                    }
                    organizationLogoUrl={organization?.logoSignedUrl}
                />
            </>
        );
    }

    return <Box>{children}</Box>;
};
