import {
    Autocomplete,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { useCallback, useMemo } from 'react';
import { themeColors } from '../theme';
import { LabelledEntityWithId } from '../types/common';
import { getLocaleFromBrowser } from '../utils/locale/getDefaultLocale';

type SingleSelectProps<T extends LabelledEntityWithId> = {
    options: T[];
    setValue: (value: T | null) => void;
    color: 'primary' | 'secondary';
    label: string;
    currentValueId?: string;
    helperText: string;
    disabled: boolean;
    dataQa?: string;
};

export const SingleSelect = <T extends LabelledEntityWithId>({
    options,
    color,
    setValue,
    label,
    currentValueId,
    disabled,
    helperText,
    dataQa,
}: SingleSelectProps<T>) => {
    const sortedOptions = useMemo(
        () =>
            options.sort((a, b) =>
                a.label.localeCompare(b.label, getLocaleFromBrowser(), {
                    sensitivity: 'base',
                    numeric: true,
                    ignorePunctuation: true,
                }),
            ),
        [options],
    );
    const currentValue =
        sortedOptions.find((option) => option._id === currentValueId) || null;

    const handleAutocompleteChange = useCallback(
        (_event: React.SyntheticEvent, value: T | null) => {
            setValue(value);
        },
        [setValue],
    );

    const handleSelectChange = useCallback(
        (event: SelectChangeEvent<string>) => {
            const selectedValue = event.target.value as string;
            const selected =
                sortedOptions.find((option) => option._id === selectedValue) ||
                null;
            if (selected && selected._id !== currentValueId) {
                setValue(selected);
            }
        },
        [sortedOptions, currentValueId, setValue],
    );
    return options.length > 5 ? (
        <Autocomplete<T>
            color={color}
            options={sortedOptions}
            getOptionLabel={(option) => option.label}
            disabled={disabled}
            value={currentValue}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            data-qa={dataQa}
            onChange={handleAutocompleteChange}
            ListboxProps={{
                sx: {
                    '& .MuiAutocomplete-option.Mui-focused': {
                        backgroundColor: themeColors.blue[50],
                    },
                    "& .MuiAutocomplete-option[aria-selected='true']": {
                        backgroundColor: themeColors.blue[100],
                    },
                    "& .MuiAutocomplete-option.Mui-focused[aria-selected='true']":
                        {
                            backgroundColor: themeColors.blue[100],
                        },
                },
            }}
            renderInput={(params) => (
                <TextField
                    color={color}
                    required={true}
                    helperText={helperText || ' '}
                    {...params}
                    label={label}
                    variant="outlined"
                />
            )}
        />
    ) : (
        <FormControl
            color={color}
            fullWidth={true}
            variant="outlined"
            required={true}
        >
            <InputLabel color={color} id="select-label">
                {label}
            </InputLabel>
            <Select
                color={color}
                labelId="select-label"
                required={true}
                // defaultValue=""
                disabled={disabled}
                value={currentValueId || ''}
                fullWidth={true}
                onChange={handleSelectChange}
                data-qa={dataQa}
                label={label}
                MenuProps={{
                    sx: {
                        // Domyślny hover
                        '& .MuiMenuItem-root.Mui-selected:hover': {
                            backgroundColor: themeColors.blue[50],
                        },
                        '& .MuiMenuItem-root.Mui-focusVisible': {
                            backgroundColor: themeColors.blue[50],
                        },
                        // Wybrany element
                        '& .MuiMenuItem-root.Mui-selected': {
                            backgroundColor: themeColors.blue[100],
                        },
                        // Zwykły hover na niewybrane elementy
                        '& .MuiMenuItem-root:hover': {
                            backgroundColor: 'rgba(25, 118, 210, 0.08)', // domyślny kolor MUI dla hovera
                        },
                    },
                }}
            >
                {sortedOptions.map((option) => (
                    <MenuItem color={color} key={option._id} value={option._id}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText
                color={color}
                sx={{ height: '24px' }}
                required={true}
            >
                {helperText || ' '}
            </FormHelperText>
        </FormControl>
    );
};
